import React from "react";
import TestimonialsCard from "../TestimonialCard/TestimonialsCard";
import "./Testimonials.scss";

function Testimonials() {
  return (
    <div>
      <section id="testimonials">
        <div className="testimonials-container">
          <h1 className="testimonialsTitle">Testimonials</h1>
          <p className="testimonialsSubtitle">
            {/* Look at what others have shared. */}
            Currently under development
          </p>
          <TestimonialsCard />
        </div>
      </section>
    </div>
  );
}

export default Testimonials;
