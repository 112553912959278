import React from "react";
import "./Footer.scss";

import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import InstagramIcon from "@material-ui/icons/Instagram";
// import FacebookIcon from "@material-ui/icons/Facebook";

function Footer() {
  return (
    <div>
      <footer>
        <div className="footer-container">
          <h1 className="footerTitle">Thank you for visiting my portfolio!</h1>
          <h3 className="footerSubtitle">
            To contact me please use any of the links below:
          </h3>
          <div className="icon-container">
            <a className="links" href="mailto:trancetribal@bellsouth.net">
              <EmailIcon className="email" />
            </a>

            <a
              className="links"
              href="https://www.linkedin.com/in/orlando-llerena/"
            >
              <LinkedInIcon className="linked" />
            </a>

            <a className="links" href="https://github.com/OrlandoLlerena">
              <GitHubIcon className="github" />
            </a>

            {/* <TwitterIcon className="twitter-icon" /> */}
            {/* <InstagramIcon className="instagram" /> */}
            {/* <FacebookIcon className="facebook" /> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
