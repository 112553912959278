import React from "react";
import "./AboutMe.scss";
import pic from "../../assets/images/Orlando-flip.jpg";

function AboutMe() {
  return (
    <div>
      <section id="about">
        <div className="about-container">
          <div className="about-title">About Me</div>
          <div className="about-subtitle"> Full Stack Web Developer </div>
          <div className="portrait-container">
            <div className="portrait-picture_box">
              <img className="portrait" src={pic} alt="selfie" />
            </div>
            <div className="about-paragraph_top">
              <div className="paragraph-top">
                <p>
                  I'm Orlando Llerena, transitioning from a decade-long
                  corporate training and instructional design journey. My
                  expertise lies in communication, team building, and mentoring,
                  all while grasping the vital role of technology in shaping
                  success.
                </p>
              </div>
              <div className="paragraph-top">
                <p>
                  With a passion for technology, I graduated from BrainStation
                  (formerly Wyncode Miami) and now seek a full-time role in the
                  technology sector. With a fresh outlook, quick learning, and a
                  love for challenges, I'm excited to apply my background to
                  innovative solutions. Merging my training experience with
                  technology innovation thrills me, and I'm eager to contribute
                  to your project or team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutMe;
