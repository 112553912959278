import React from "react";
import "./Home.scss";

function Home() {
  return (
    <div>
      <section id="home">
        <div className="home-container">
          <div className="home-title">ORLANDO LLERENA</div>
          <div className="home-subtitle">
            {" "}
            genuine + collaborative + dependable + creative + humble + pragmatic =
            ME{" "}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
