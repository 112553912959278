import React from "react";
import "./TestimonialsCard.scss";
import TestimonialsJson from "../../data/Testimonials.json";

function TestimonialsCard() {
  return (
    <div className="testimonial-flex">
      {TestimonialsJson.map((testimonial) => {
        return (
          <div key={testimonial.id}>
            <div className="testimonialCard-container">
              <div className={testimonial.image}></div>
              <h1>{testimonial.name}</h1>
              <h3>{testimonial.rating}</h3>
              <p>{testimonial.feedback}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TestimonialsCard;
