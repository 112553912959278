import React from "react";
import "./ModalCallToAction.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";

export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState(" ");
  const [email, setEmail] = React.useState(" ");
  const [subject, setSubject] = React.useState(" ");
  const [message, setMessage] = React.useState(" ");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalInputs = (modalData, e) => {
    modalData(e.target.value);
  };

  function sendEmail(e) {
    e.preventDefault();
    const emailData = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };

    emailjs
      .send(
        `${process.env.REACT_APP_EMAIL_JS_SERVICE_ID}`,
        `${process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID}`,
        emailData,
        `${process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY}`
      )
      .then(
        (result) => {
          handleClose();
          toast.success("You email is on the way!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        },
        (error) => {
          toast.error("This email was not sent.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
    e.target.reset();
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Contact me
        <SendIcon />
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Want to begin a project?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the fields below and provide basic details for the
            project to get things started.
          </DialogContentText>
          <form className="form" onSubmit={sendEmail}>
            <TextField
              id="nameStyle"
              required
              label="Full Name"
              style={{ margin: 8 }}
              placeholder="Please enter your full name"
              fullWidth
              multiline
              minRows={2}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: { color: "#fca311" },
              }}
              variant="filled"
              onChange={(e) => modalInputs(setName, e)}
            />

            <TextField
              id="emailStyle"
              required
              label="Email"
              type="email"
              style={{ margin: 8 }}
              placeholder="example@example.com"
              fullWidth
              multiline
              minRows={2}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: { color: "#fca311" },
              }}
              variant="filled"
              onChange={(e) => modalInputs(setEmail, e)}
            />

            <TextField
              id="subjectStyle"
              label="Subject"
              style={{ margin: 8 }}
              placeholder="Title of Project"
              fullWidth
              multiline
              minRows={2}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: { color: "#fca311" },
              }}
              variant="filled"
              onChange={(e) => modalInputs(setSubject, e)}
            />

            <TextField
              id="messageStyle"
              required
              label="Message"
              style={{ margin: 8 }}
              placeholder="Please provide additional details"
              fullWidth
              multiline
              minRows={8}
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: { color: "#fca311" },
              }}
              variant="filled"
              onChange={(e) => modalInputs(setMessage, e)}
            />
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Send
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
