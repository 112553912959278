import React from "react";
import "./ProjectsCard.scss";
import Projectsjson from "../../data/Projects.json";

function ProjectsCard() {
  return (
    <div className="project-flex">
      {Projectsjson.map((project) => {
        return (
          <div className="card-container" key={project.id}>
            <div className={project.class}>
              <h1 id={project.id}>{project.title}</h1>
              <a
                className="btn-link"
                href={project.link ? project.link : undefined}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn">
                  {project.link ? "View Site" : "Pending"}
                </button>
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ProjectsCard;
