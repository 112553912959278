import React from "react";
import StarBackground from "./components/StarBackground/StarBackground";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import AboutMe from "./components/AboutMe/AboutMe";
import Projects from "./components/Projects/Projects";
import Testimonials from "./components/Testimonial/Testimonials";
import CallToAction from "./components/CallToAction/CallToAction";
import Footer from "./components/Footer/Footer";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <StarBackground />
      <header className="App-header">
        <Navbar />
      </header>
      <Home />
      <AboutMe />
      <Projects />
      <Testimonials />
      <CallToAction />
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default App;
