import React from "react";
import "./CallToAction.scss";
import ResponsiveDialog from "../ModalCallToAction/ModalCallToAction";

function CallToAction() {
  return (
    <div>
      <section id="callToAction">
        <div className="cta-container">
          <h1 className="ctaTitle">Connect with me.</h1>
          <p className="ctaSubtitle">Looking to begin a project of your own?</p>
          <p className="ctaSubtitle">Click the link and let's get started.</p>
          <ResponsiveDialog />
        </div>
      </section>
    </div>
  );
}

export default CallToAction;
