import React from "react";
import "./Projects.scss";
import ProjectsCard from "../ProjectsCard/ProjectsCard";

function Projects() {
  return (
    <div>
      <section id="projects">
        <div className="projects-container">
          <h1 className="projects-title">Projects</h1>
          <p className="projects-subtitle">Want to see more?</p>
          <div>
            <ProjectsCard />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Projects;
