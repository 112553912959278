import React, { useEffect, useState } from "react";
import "./Navbar.scss";

function Navbar() {
  const [open, setOpen] = useState(false);

  const showBurger = () => {
    setOpen(true);
  };

  const burgerListener = () => {
    document.addEventListener("click", closeBurger);
  };

  const closeBurger = () => {
    setOpen(false);
    document.removeEventListener("click", closeBurger);
  };

  useEffect(() => {
    open ? burgerListener() : closeBurger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <div className="mobile-nav">
        <div id="container" onClick={showBurger}>
          <div id="burger" className={open ? "active" : ""}>
            <div className="bun top"></div>
            <div className="filling"></div>
            <div className="bun bottom"></div>
          </div>
        </div>
        <nav className={open ? "show" : ""}>
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li className="green">
              <a href="#about">About Me</a>
            </li>
            <li className="red">
              <a href="#projects">Projects</a>
            </li>
            <li className="yellow">
              <a href="#testimonials">Testimonials</a>
            </li>
            <li className="purple">
              <a href="#callToAction">Contact Me</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="laptop-nav">
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li className="green">
            <a href="#about">About Me</a>
          </li>
          <li className="red">
            <a href="#projects">Projects</a>
          </li>
          <li className="yellow">
            <a href="#testimonials">Testimonials</a>
          </li>
          <li className="purple">
            <a href="#callToAction">Contact Me</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
